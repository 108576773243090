import emailjs from 'emailjs-com';
import React, { useState } from 'react';

function ContactPage() {
    const [formDetails, setFormDetails] = useState({
        user_name: '',
        user_email: '',
        message: '',
    });

    const handleChange = (e) => {
        setFormDetails({ ...formDetails, [e.target.name]: e.target.value });
    };

    const [showSuccessMessage, setShowSuccessMessage] = useState(false);

    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.send('service_rqbktg4', 'template_5tqhjae', formDetails, 'GPYVjqaK47VJQST5b')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setShowSuccessMessage(true); // Show success message pop-up
                // Reset form or show a success message
            }, (err) => {
                console.log('FAILED...', err);
            });
    };

    return (
        <div>
            {showSuccessMessage && (
                <div style={{ position: 'absolute', top: '20%', left: '50%', transform: 'translate(-50%, -50%)', backgroundColor: 'lightgreen', padding: '20px', borderRadius: '10px' }}>
                    Email successfully sent!
                </div>
            )}
            <form onSubmit={sendEmail}>
                <input type="text" name="user_name" placeholder="Your Name" onChange={handleChange} />
                <input type="email" name="user_email" placeholder="Your Email" onChange={handleChange} />
                <textarea name="message" placeholder="Your Message" onChange={handleChange}></textarea>
                <button type="submit">Send</button>
            </form>
        </div>
    );
    
};

export default ContactPage;
